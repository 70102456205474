<template>
  <div
    v-show="showAddressEntry"
    class="cart-address-wrapper"
  >
    <div
      class="wrapper-left"
      @click="openListDialogHandle"
    >
      <sui_icon_location_16px_1 size="18px" />
      <span class="address-content">
        {{ language.SHEIN_KEY_PC_21715 }} {{ addressInfo }}
      </span>
    </div>

    <!-- 地址列表弹窗 -->
    <SLazyMount>
      <s-dialog
        v-model:visible="isShowListDialog"
        :type="'W720'"
        :show-close="true"
      >
        <template #title>
          {{ language.SHEIN_KEY_PC_16575 }}
        </template>
        <list
          v-if="source.length && isLoaded"
          ref="addressList"
          :source="source"
          :language="language"
          :show-address-id="showAddressId"
          @select-item="selectItem"
        />
        <template #footer>
          <div class="footer-wrapper">
            <p
              class="select-other"
              @click="selectOtherHandle"
            >
              <Icon
                name="sui_icon_nav_global_24px"
                size="16px"
              />
              <span class="select-other_tip">{{ language.SHEIN_KEY_PC_21735 }}</span>
            </p>
            <s-button-group
              hor
              :width="500"
            >
              <s-button-group-item @click="closeListDialogHandle">
                {{ language.SHEIN_KEY_PC_15748 }}
              </s-button-group-item>
              <s-button-group-item
                :type="['primary']"
                :disabled="!selectFlag"
                @click="selectListItemHandle"
              >
                {{ language.SHEIN_KEY_PC_15862 }}
              </s-button-group-item>
            </s-button-group>
          </div>
        </template>
      </s-dialog>
    </SLazyMount>

    <!-- 地址选择弹窗 -->
    <SLazyMount>
      <s-dialog
        v-model:visible="isShowSelectDialog"
        :type="'W720'"
        :show-close="true"
      >
        <template #title>
          <p>{{ language.SHEIN_KEY_PC_16575 }}</p>
          <p class="title-tip">
            {{ language.SHEIN_KEY_PC_21716 }}
          </p>
        </template>
        <address-select
          v-if="isLoaded"
          ref="addressSelect"
          v-model:select-btn-disabled="selectBtnDisabled"
          :language="language"
          @address-select-handle="addressSelectHandle"
        />
        <template #footer>
          <s-button-group
            hor
            :width="500"
          >
            <s-button-group-item @click="closeSelectDialogHandle">
              {{ language.SHEIN_KEY_PC_15748 }}
            </s-button-group-item>
            <s-button-group-item
              :type="['primary']"
              :disabled="selectBtnDisabled"
              @click="selectAddressHandle"
            >
              {{ language.SHEIN_KEY_PC_15862 }}
            </s-button-group-item>
          </s-button-group>
        </template>
      </s-dialog>
    </SLazyMount>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
const { GB_cssRight, SiteUID } = gbCommonInfo
import { getLocalStorage, setLocalStorage } from '@shein/common-function'

import { daEventCenter } from 'public/src/services/eventCenter'
import schttp from 'public/src/services/schttp'
import { defineAsyncComponent } from 'vue'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import { Icon, sui_icon_location_16px_1 } from '@shein-aidc/icon-vue3'
import UserInfoManager from 'public/src/services/UserInfoManager/index'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount'

daEventCenter.addSubscriber({ modulecode: '1-7-1' })
export default {
  name: 'CartAddress',
  components: {
    SLazyMount,
    sui_icon_location_16px_1,
    Icon,
    list: defineAsyncComponent(() => import('./list')),
    addressSelect: defineAsyncComponent(() => import('./addressSelect')),
  },
  data () {
    return {
      isShowListDialog: false,
      isShowSelectDialog: false,
      source: [],
      selectData: null,
      selectFlag: null,
      selectBtnDisabled: true,
      cache: null,
      addressInfo: '',
      user: null,
      showAddressId: '',
      initFlag: false,
      isLoaded: false
    }
  },
  computed: {
    ...mapState(['language', 'cartInfo', 'ABTParams']),
    showAddressEntry () {
      return this.ABTParams?.CartAddress?.param?.cart_address === 'on'
    }
  },
  watch: {
    cartInfo: {
      handler (val) {
        const isLogined = !!isLogin()
        if (val.carts.length && isLogined && !this.initFlag) {
          this.init()
          this.initFlag = true
        }
      },
      deep: true
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions(['handleMallShippingInfo']),
    async init () {
      this.user = await this.getUserInfo()
      let [data, cache] = [null, null]
      this.source = await this.getAddressList()
      const addressCookie = this.getLocalStorageAndRemoveCookie('addressCookie')
      if (addressCookie) {
        cache = typeof addressCookie == 'string' ? JSON.parse(addressCookie) : addressCookie
        if (!this.isValidCache(cache)) cache = null
      }
      // 如果终端有地址前置缓存并且缓存的是详细地址并且用户已登录
      if (cache && cache.addressId && UserInfoManager.isLogin()) {
        // 如果宅配地址列表不为空， 并且缓存的详细地址在宅配地址列表中
        const souceItem = this.source.find(val => val.addressId == cache.addressId)
        if (this.source.length && souceItem) {
          data = souceItem
        } else {
          data = await this.getSingleAddressList(cache.addressId)
        }
        this.showAddressId = data?.addressId || ''
      } else if (cache && !cache.addressId) { // 终端有地址前置缓存且缓存的是国家省市区
        data = cache
      } else { // 其他场景均取默认地址
        data = await this.getDefaultAddress()
        data && (await this.setCache(data))
      }
      this.addressInfo = data ? this.getAddressLable(data) : ''
      daEventCenter.triggerNotice({ daId: '1-7-1-25', extraData: { address_type: this.showAddressId ? '1' : '0' } })
    },
    getUserInfo(){
      return UserInfoManager.get({ key: 'UserInfo', actionType: 'cart/getUserInfo' }) || null
    },
    getLocalStorageAndRemoveCookie (key) {
      let cache = ''
      try {
        cache = getLocalStorage(key)
      } catch (error) {
        console.log(error)
        cache = ''
      }
      return cache
    },
    async setCacheBysetLocalStorage (key, data) {
      const res = await schttp({
        method: 'POST',
        url: '/api/common/busineseCache/update',
        data: {
          cacheData: data,
          scanType: 'addrcache'
        }
      })
      if (res?.code == '0') {
        setLocalStorage({
          key,
          value: data
        })
      }
    },
    // 判断缓存是否有效
    isValidCache (cache) {
      // 判断是否同一个用户
      if (cache.memberId != this.user.member_id) return false
      // 判断是否退出登录过
      if (cache.loginTimeLast != this.user.login_time_last) return false
      // 判断是否同一个站点
      if (cache.siteUid != SiteUID) return false
      return true
    },
    // 缓存数据
    async setCache (item, isUserHandle = '0') {
      const addressCookie = {
        addressId: item?.addressId || '', // 详细地址id
        countryId: item?.countryId || '', // 国家id
        stateId: item?.stateId || '', // 省id
        cityId: item?.cityId || '', // 城市id
        districtId: item?.districtId || '', // 区id
        createdTime: new Date().getTime(), // 缓存生成时间
        memberId: this.user.member_id, // 用户id
        loginTimeLast: this.user.login_time_last, // 用户最后登录时间
        isUserHandle: isUserHandle, // 是否为用户主动操作 是：1，否：0
        siteUid: SiteUID
      }
      const realSetData = {}
      Object.keys(addressCookie).forEach(val => {
        if (addressCookie[val]) {
          realSetData[val] = addressCookie[val]
        }
      })
      await this.setCacheBysetLocalStorage('addressCookie', realSetData)
      if (item && !item.addressId) {
        this.setLocal(item)
      }
      if (isUserHandle == '1') {
        this.$message({
          message: this.language.SHEIN_KEY_PC_21717,
          type: 'info'
        })
      }
    },
    setLocal (item) {
      const addressCache = {}
      // 详细地址缓存... 省市区...
      if (item?.countryId) {
        addressCache[item?.countryId] = item?.country || item?.countryName || ''
      }
      if (item?.cityId) {
        addressCache[item?.cityId] = item?.city || ''
      }
      if (item?.stateId) {
        addressCache[item?.stateId] = item?.state_province || item?.state || ''
      }
      if (item?.districtId) {
        addressCache[item?.districtId] = item?.district
      }
      if (JSON.stringify(addressCache) !== '{}') {
        localStorage.setItem('address_cache', JSON.stringify(addressCache))
      } else {
        localStorage.removeItem('address_cache')
      }
    },
    // 获取列表选择后的地址显示内容
    getAddressLable (info) {
      let address = ''
      let localCache = {
        city: '',
        state: '',
        district: '',
        postcode: ''
      }
      if (info?.addressId) { // 是否为详细地址
        address = GB_cssRight ? `${info?.address2 || ''} ${info?.address1 || ''}` : `${info?.address1 || ''} ${info?.address2 || ''}`
        localCache = {
          city: info?.city || '',
          state: info?.state || '',
          district: info?.district || '',
          postcode: info?.postcode || '',
        }
      } else {
        const strs = []
        const addressCacheStr = window?.localStorage?.getItem('address_cache')
        const addressCache = addressCacheStr && JSON.parse(addressCacheStr)
        if (addressCache) {
          if (info?.countryId) {
            strs.push(addressCache[info?.countryId])
          }
          if (info?.stateId) {
            strs.push(addressCache[info?.stateId])
            localCache.state = addressCache[info?.stateId]
          }
          if (info?.cityId) {
            strs.push(addressCache[info?.cityId])
            localCache.city = addressCache[info?.cityId]
          }
          if (info?.districtId) {
            strs.push(addressCache[info?.districtId])
            localCache.district = addressCache[info?.districtId]
          }
        }
        address = strs.join(', ')
      }
      localStorage.setItem('localCache', JSON.stringify(localCache))
      return address
    },
    // 按照客户的默认收货地址取对应国家
    async getDefaultAddress () {
      try {
        let res = await schttp({
          url: `/api/common/defaultAddress/get`,
        })
        return res?.info || null
      } catch (e) {
        return false
      }
    },
    async getSingleAddressList (addressId) {
      try {
        let res = await schttp({
          url: `/api/user/addressbook/singleAddress/get`,
          params: {
            addressId: addressId
          }
        })
        return res?.info?.address || null
      } catch (e) {
        return false
      }
    },
    // 获取地址列表
    getAddressList () {
      return schttp({
        url: '/api/user/addressbook/list/query',
        method: 'POST'
      }).then(res => {
        return res?.addressList || []
      }).catch(() => {
        return []
      })
    },
    // 打开地址弹窗
    openListDialogHandle () {
      this.isLoaded = true
      if (this.source.length) {
        this.isShowListDialog = true
        this.$nextTick(() => {
          if (!this.selectData?.addressId) {
            this.$refs.addressList?.clearData?.()
          }
          this.$refs.addressSelect?.clearData()
        })
        return
      }
      this.isShowSelectDialog = true
      this.$nextTick(() => {
        this.$refs.addressSelect?.clearData()
      })
      daEventCenter.triggerNotice({ daId: '1-7-1-26', extraData: { address_type: this.showAddressId ? '1' : '0' } })
    },
    closeListDialogHandle () {
      this.selectData = null
      this.isShowListDialog = false
    },
    // 选中地址，关闭弹窗
    async selectListItemHandle () {
      this.$refs.addressList.clearData()
      this.isShowListDialog = false
      this.showAddressId = this.selectFlag?.addressId || ''
      this.selectData = this.selectFlag
      this.exportsHandle(this.selectFlag)
      await this.setCache(this.selectData, '1')
      this.addressInfo = this.getAddressLable(this.selectData)
      // this.handleShippingInfo({
      //   ...this.cartInfo,
      //   shippingCountryId: this.selectData.countryId
      // })
      this.handleMallShippingInfo({ cartInfo: this.cartInfo })
    },
    exportsHandle (data) {
      const addressCookie = this.getLocalStorageAndRemoveCookie('addressCookie')
      const cache = typeof addressCookie == 'string' ? JSON.parse(addressCookie) : addressCookie
      daEventCenter.triggerNotice({
        daId: '1-7-1-32',
        extraData: {
          is_country: cache.countryId == data.countryId ? '0' : '1',
          is_state: cache.stateId == data.stateId ? '0' : '1',
          is_area: cache.districtId == data.districtId ? '0' : '1',
          is_city: cache.cityId == data.cityId ? '0' : '1'
        }
      })
    },
    // 列表选中地址
    selectItem (item) {
      if (!item) return
      this.selectFlag = item
    },
    // 选择其他区域
    selectOtherHandle () {
      this.isShowListDialog = false
      this.isShowSelectDialog = true
      if (this.selectData?.addressId) {
        this.$nextTick(() => {
          this.$refs.addressSelect?.clearData()
        })
      }
      daEventCenter.triggerNotice({ daId: '1-7-1-29' })
    },
    // 关闭地址选择弹窗
    closeSelectDialogHandle () {
      this.isShowSelectDialog = false
      if (this.source.length) {
        this.isShowListDialog = true
      }
    },
    // 联级地址选择事件
    addressSelectHandle ({ cache, flag }) {
      this.selectBtnDisabled = flag
      if (cache) {
        this.cache = cache
      } else {
        this.cache = null
      }
    },
    async selectAddressHandle () {
      this.isShowSelectDialog = false
      this.selectData = this.cache
      this.selectFlag = null
      await this.setCache(this.cache, '1')
      this.addressInfo = this.getAddressLable(this.cache)
      this.showAddressId = ''
      // this.handleShippingInfo({
      //   ...this.cartInfo,
      //   shippingCountryId: this.cache.countryId
      // })
      this.handleMallShippingInfo({ cartInfo: this.cartInfo })
      daEventCenter.triggerNotice({ daId: '1-7-1-31' })
    },
  }
}
</script>

<style lang="less">
.cart-address-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  margin: 0;
  background: @sui_color_gray_weak2;
  border-radius: 23px;
  overflow: hidden;
  .wrapper-left {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    line-height: 13.6px;
    color: @sui_color_gray_dark2;
    overflow: hidden;
    .address-content {
      .margin-l(4px);
      line-height: 16px;
      .text-overflow()
    }
  }
  .wrapper-right {
    cursor: pointer;
    font-size: 14px;
    color: #2D68A8;
    line-height: 16px;
  }
}
.footer-wrapper {
  border-top: 1px solid #E5E5E5;
  .select-other {
    width: 100%;
    display: flex;
    align-items: center;
    color: #2D68A8;
    font-size: 14px;
    margin: 16px 0;
    cursor: pointer;
    .select-other_tip {
      .margin-l(4px);
    }
  }
}
.title-tip {
  color: #666666;
  font-weight: normal;
  font-size: 14px;
  font-family: "Arial", "Helvetica", sans-serif !important; /* stylelint-disable-line declaration-no-important */
}
</style>
